<template>
  <div>
    <topbar
      :titulo="$t('ACADEMICO_MATRICULAS.NOVA_MATRICULA')"
      :subtitulo="$t('ACADEMICO_MATRICULAS.MATRICULA_PAGE_DESCRICAO_NOVO')"
      :breadcrumbs="breadcrumbs"
    >
      <template #header>
        <b-button
          variant="secondary"
          class="mr-2"
          @click="irPara('matriculas')"
        >
          {{ $t('GERAL.VOLTAR') }}
        </b-button>
      </template>
    </topbar>

    <b-tabs>
      <b-tab :title="`1 - ${$t('GERAL.DADOS_GERAIS')}`" class="pt-2">
        <dados-aluno
          :form="form"
          :pessoasFiltradas="pessoasFiltradas"
          :periodoLetivo="opcoes.periodoLetivo"
          :loadingPessoas="loadingPessoas"
          @pesquisa-por-nome="getPessoasSemMatriculaPorNome"
        />

        <vinculos
          :pessoasFiltradas="vinculos"
          :form="form"
          @contato-vinculo="buscarContatoResponsavel"
          :loadingPessoas="loadingPessoas"
          @pesquisa-por-nome="getPessoasSemMatriculaPorNomeVinculo"
        />

        <observacoes :form="form" />

        <modal-contato-vinculos
          :exibir="modais.vinculos"
          :form="modais.vinculosDados"
          @fechar="closeModal('vinculos')"
        />
      </b-tab>
      <b-tab :title="`2 - ${$t('FICHA_SAUDE.FICHA_SAUDE')}`" class="pt-2">
        <ficha-saude
          :fichaSaude="fichaSaude"
          @refs="atualizarRefs"
          @ficha-saude="setFichaSaude"
          ref="fichaSaudeRef"
        />
        <b-row>
          <b-col cols="12" class="text-right">
            <b-button variant="primary" @click="salvar">
              {{ $t('GERAL.SALVAR') }}
            </b-button>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import modais from '@/common/utils/modais';
import rotas from '@/common/utils/rotas';

// Services:
import MatriculaService from '@/common/services/matriculas/matriculas.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';
import PessoaService from '@/common/services/pessoa/pessoa.service';
import FichaSaudeService from '@/common/services/ficha-saude/ficha-saude.service';

// Models:
import { Matricula } from '@/common/models';

// Components:
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';
import {
  DadosAluno,
  Vinculos,
  Observacoes,
  FichaSaude,
} from '@/views/academico/matriculas/components/index';
import ModalContatoVinculos from './components/vinculos/contatos/Modal.vue';

export default {
  components: {
    Topbar,
    DadosAluno,
    Vinculos,
    ModalContatoVinculos,
    Observacoes,
    FichaSaude,
  },
  data() {
    return {
      form: new Matricula({
        pessoaId: this.$route.params.dados,
      }),
      fichaSaude: {
        planoDeSaude: '',
        numeroCarteirinha: '',
        diabetes: false,
        medicamentoContinuo: false,
        nomeMedicamento: '',
        motivoMedicamento: '',
        alergia: false,
        especificaoAlergia: '',
        intoleranciaLactose: false,
        intoleranciaGluten: false,
        refluxo: false,
        deficiencia: false,
        especificacaoDeficiencia: '',
        tiposDeficiencias: [],
        observacoes: '',
        matriculaId: '',
      },
      pessoasVinculos: [],
      pessoasDadosAluno: [],
      opcoes: {
        periodoLetivo: [],
      },
      modais: {
        vinculos: false,
        vinculosDados: {},
      },
      loadingPessoas: false,
      breadcrumbs: [
        { titulo: this.$t('ACADEMICO.ACADEMICO') },
        { titulo: this.$t('ACADEMICO_MATRICULAS.MATRICULAS') },
        { titulo: this.$t('ACADEMICO_MATRICULAS.NOVA_MATRICULA') },
      ],
    };
  },
  mounted() {
    // this.getPessoasSemMatricula();
    this.getPeriodoLetivo();
  },
  computed: {
    pessoasFiltradas() {
      var filtro = this.pessoasDadosAluno;
      this.form.vinculos.forEach((vinculo) => {
        filtro = filtro.filter((item) => item.id !== vinculo.pessoaId);
      });
      return filtro.length > 0
        ? filtro
        : [{ text: 'Digite o nome do aluno', value: undefined }];
    },
    vinculos() {
      return this.pessoasVinculos.filter(
        (item) => item.id !== this.form.pessoaId
      );
    },
  },
  methods: {
    getPessoasSemMatriculaPorNome(nome) {
      this.loadingPessoas = true;
      PessoaService.buscarTodosSemMatriculaPorNome(nome)
        .then((data) => {
          this.pessoasDadosAluno = data.itens;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingPessoas = false;
        });
    },
    getPessoasSemMatriculaPorNomeVinculo(nome) {
      this.loadingPessoas = true;
      PessoaService.buscarTodosSemMatriculaPorNome(nome)
        .then((data) => {
          this.pessoasVinculos = data.itens;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.loadingPessoas = false;
        });
    },
    getPeriodoLetivo() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('periodo-letivo')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.periodoLetivo = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    obterDescontos(listaDescontosAtualizada) {
      this.form.descontos = listaDescontosAtualizada;
    },
    passarValorDeIdParaPessoa() {
      if (!this.form.pessoaId) {
        return mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('ACADEMICO_MATRICULAS.MATRICULA_SEM_VINCULO')
        );
      }
    },
    // FUNÇÕES: CRUD:
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    validarSeExisteResponsavelFinanceiro() {
      return this.form.vinculos.some(
        (obj) => obj.tipoResponsavel === 188 || obj.tipoResponsavel === 469
      );
    },
    validarResponsavelFinanceiroComCPF() {
      return this.form.vinculos.some(
        (obj) =>
          (obj.tipoResponsavel === 188 || obj.tipoResponsavel === 469) &&
          obj.pessoaId.cpf
      );
    },
    async salvar() {
      if (!this.$refs.fichaSaudeRef.validarFormulario()) return;

      if (!this.validarSeExisteResponsavelFinanceiro()) {
        return mensagem.warning(
          'Deve haver pelo menos um responsável com a resposabilidade financeira'
        );
      }
      if (this.validarResponsavelFinanceiroComCPF()) {
        return mensagem.warning('Responsável financeiro deve ter CPF');
      }
      this.passarValorDeIdParaPessoa();
      if (!this.form.vinculos.length) {
        return mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('ACADEMICO_MATRICULAS.MATRICULA_SEM_VINCULO')
        );
      }

      this.$store.dispatch(START_LOADING);

      try {
        const response = await MatriculaService.salvar(this.form);
        if (response?.data?.data?.matricula?.id)
          await FichaSaudeService.salvarFicha({
            ...this.fichaSaude,
            matriculaId: response?.data?.data?.matricula?.id,
          });

        mensagem.sucesso(
          this.$t('GERAL.SUCESSO'),
          this.$t('ACADEMICO_MATRICULAS.MENSAGEM_CADASTRADO')
        );

        this.form = new Matricula({
          pessoaId: this.$route.params.dados,
        });

        this.fichaSaude = {};

        this.irPara('matriculas');
      } catch (err) {
        mensagem.showErrors(err);
      } finally {
        this.$store.dispatch(STOP_LOADING);
      }
    },
    // métodos para salvar descrição no banco:
    buscarContatoResponsavel(modal, vinculoId) {
      this.$store.dispatch(START_LOADING);
      PessoaService.buscarPorId(vinculoId)
        .then(({ data }) => {
          this.openModal(modal, data);
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // FUNÇOES FICHA DE SAUDE
    setFichaSaude(ficha) {
      this.fichaSaude = ficha;
    },

    atualizarRefs(refs) {
      this.$refs = { ...this.$refs, ...refs };
    },

    // FUNÇÕES MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
    irPara(rota) {
      rotas.irPara(rota);
    },
  },
};
</script>
